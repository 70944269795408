import {Box} from '@mui/material';
import {FirebaseError} from 'firebase/app';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import {useMemo} from 'react';
import {useTranslation} from 'next-i18next';
import {EmailInput, PasswordInput} from '../common/input';
import {useUser} from '../../provider/UserProvider';
import WithTranslateFormErrors from '../../utils/useTranslateFormErrors';
import yup, {yupRules, YupTypes} from '../../utils/yup';
import {CustomLink, FullWidthButton} from '../common/button';
import {useRouter} from 'next/router';

type Inputs = Pick<YupTypes, 'email' | 'password'>;
type Helpers = FormikHelpers<Inputs>;
type Props = FormikProps<Inputs>;

const initialValues = {
    email: '',
    password: '',
};

export default function LoginForm() {
    const router = useRouter();
    const i18n = useTranslation('common');
    const {loginByPassword} = useUser();

    const validationSchema = useMemo(() => {
        const {email, password} = yupRules(i18n);
        const validationSchema = yup.object().shape({email, password});
        return validationSchema;
    }, [i18n]);

    async function login(values: Inputs, {resetForm, setErrors, setSubmitting}: Helpers) {
        try {
            await loginByPassword(values.email, values.password);
        } catch (e) {
            if (e instanceof FirebaseError) {
                const error = e as FirebaseError;
                if (
                    error.code == 'auth/wrong-password' ||
                    error.code == 'auth/user-not-found'
                ) {
                    setErrors({password: i18n.t('error.email-or-password')});
                }
            }
        }
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={login}
            >
                {(formik: Props) => (
                    <WithTranslateFormErrors
                        errors={formik.errors}
                        touched={formik.touched}
                        setFieldTouched={formik.setFieldTouched}
                        validationSchema={validationSchema}
                    >
                        <form id="register" autoComplete="on" onSubmit={formik.handleSubmit}>
                            <Box display={'flex'} flexDirection="row" justifyContent="center">
                                <Box
                                    display={'flex'}
                                    flexDirection="column"
                                    alignItems="center"
                                >
                                    <EmailInput
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.email &&
                                            Boolean(formik.errors.email)
                                        }
                                        helperText={
                                            formik.touched.email && formik.errors.email
                                        }
                                        tips={true}
                                    />
                                    <PasswordInput
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.password &&
                                            Boolean(formik.errors.password)
                                        }
                                        helperText={
                                            formik.touched.password && formik.errors.password
                                        }
                                        autoComplete={'password'}
                                        onKeyUp={(e) => {
                                            if (e.key === 'Enter') {
                                                formik.handleSubmit();
                                            }
                                        }}
                                    />
                                    <CustomLink
                                        sx={{
                                            marginTop: '10px',
                                            alignSelf: 'flex-start',
                                            '& p': {textDecoration: 'none'},
                                            '& p:hover': {textDecoration: 'underline'},
                                        }}
                                        onClick={() => router.push('/forgotten-password')}
                                    >
                                        {i18n.t('link.forgotten')}
                                    </CustomLink>
                                </Box>
                            </Box>
                            <FullWidthButton
                                sx={{
                                    marginTop: '50px',
                                    width: '100%',
                                    borderRadius: '0px',
                                    textTransform: 'none',
                                }}
                                onClick={() => formik.handleSubmit()}
                                disabled={!formik.isValid}
                            >
                                {i18n.t('action.login')}
                            </FullWidthButton>
                        </form>
                    </WithTranslateFormErrors>
                )}
            </Formik>
        </>
    );
}
